import styled from '@emotion/styled';
import {ArrowForward} from '@polarsignals/icons';
import cx from 'classnames';
import {useRef, useState} from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const SliderItem = styled.div`
  display: flex !important;
`;

const Quote = styled.p`
  font-size: 32px;
  line-height: 44px;

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 67px;
  }
`;

const SaidBy = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 32px;

  strong {
    font-family: 'dazzed-semibold', sans-serif;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const Arrow = styled.button`
  :hover {
    opacity: 0.7;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  svg {
    fill: white;
  }
`;

const Dot = styled.button`
  transition: all 0.3s linear;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 29px;
  opacity: 0.2;

  &.active {
    width: 26px;
    height: 8px;
    opacity: 1;
  }
`;

const Testimonials = ({
  items,
}: {
  items: {imagePath: string; text: string; name: string; role: string}[];
}) => {
  const sliderRef = useRef<Slider | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    infinite: false,
    arrows: false,
    dots: false,
    beforeChange: (_current, next) => setActiveSlide(next),
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4 text-white">
        <Arrow
          className={cx(
            'col-span-1 flex hidden items-center justify-start lg:block',
            activeSlide === 0 && 'disabled'
          )}
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <ArrowForward className="rotate-180 transform" />
        </Arrow>
        <Slider className="min-h-80 col-span-12 lg:col-span-10" ref={sliderRef} {...settings}>
          {items.map((item, index) => (
            <SliderItem className="flex flex-col sm:flex-row" key={index}>
              <div className="flex items-center justify-center pr-4 pb-4 sm:pr-16 sm:pb-0">
                <Image
                  loading="lazy"
                  src={item.imagePath}
                  alt={item.name}
                  width={114}
                  height={114}
                />
              </div>
              <div className="flex flex-1 flex-col">
                <Quote>{item.text}</Quote>
                <SaidBy>
                  <strong>{item.name}</strong> {item.role}
                </SaidBy>
              </div>
            </SliderItem>
          ))}
        </Slider>
        <Arrow
          className={cx(
            'col-span-1 flex hidden items-center justify-end lg:block',
            activeSlide === items.length - 1 && 'disabled'
          )}
          onClick={() => sliderRef.current?.slickNext()}
        >
          <ArrowForward />
        </Arrow>
      </div>
      <div className="mt-6 flex translate-y-6 transform justify-center gap-2 text-center">
        {items.map((item, index) => (
          <Dot
            className={cx(index === activeSlide && 'active')}
            key={index}
            onClick={() => sliderRef.current?.slickGoTo(index)}
          />
        ))}
      </div>
    </>
  );
};

export default Testimonials;
