import styled from '@emotion/styled';
import {CaretUp} from '@polarsignals/icons';
import cx from 'classnames';
import {useState} from 'react';

const Item = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  opacity: 0.5;

  &.selected {
    opacity: 1;
    padding-bottom: 48px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #000;
  }
`;

const Question = styled.div`
  position: relative;
  z-index: 2;
  font-size: 32px;
  padding-right: 32px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const FAQItems = ({items}: {items: {question: string; answer: string}[]}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedItem = items[selectedIndex];
  return (
    <div className="grid grid-cols-12 gap-4">
      {items.map((item, index) => (
        <Item
          key={index}
          className={cx(
            'col-start-0 col-span-12 overflow-hidden lg:col-span-8 lg:col-start-3',
            selectedItem === item && 'selected'
          )}
        >
          <Question onClick={() => setSelectedIndex(selectedIndex !== index ? index : -1)}>
            {item.question}
            <CaretUp
              className={cx(
                'absolute top-0 right-0 mt-4',
                selectedItem === item && 'rotate-180 transform'
              )}
              width={22}
              height={14}
            />
          </Question>
          <p className={cx('overflow-hidden', selectedItem !== item && 'h-0')}>{item.answer}</p>
        </Item>
      ))}
    </div>
  );
};

export default FAQItems;
