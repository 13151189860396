import styled from '@emotion/styled';
import Image from 'next/image';

const Shadow = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 20px);
`;

const ScreenshotDescription = ({
  title,
  description,
  imagePath,
  bgColor,
}: {
  title: string;
  description: string;
  imagePath: string;
  bgColor: string;
}) => {
  const alpha = 60;
  const linearGradient = `linear-gradient(285.17deg, ${bgColor} 25.92%, ${bgColor}${alpha} 107.55%)`;
  return (
    <div className="my-24 flex flex-col md:flex-row">
      <div className="mb-8 flex-1 md:mb-0">
        <div className="relative mr-20">
          <Shadow
            className="absolute m-2 translate-x-8 translate-y-8 transform rounded-xl"
            style={{background: linearGradient}}
          ></Shadow>
          <Image loading="lazy" src={imagePath} alt={title} width={1106} height={594} />
        </div>
      </div>
      <div className="flex-column mt-4 flex flex-1 justify-center">
        <div className="pl-0 md:pl-8">
          <h3 className="mt-0 mb-3">{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ScreenshotDescription;
